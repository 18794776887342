import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EndPointApi } from '../../_helpers/endpointapi';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private javEndPoint = EndPointApi.getJavaEndpoint();

  constructor(public httpClient: HttpClient) {}

  get(url: string, options?: any): Observable<any> {
    url = this.updateUrl(url);
    return this.httpClient.get(url, options);
  }

  post(url: string, body: any = null, options?: any): Observable<any> {
    url = this.updateUrl(url);
    return this.httpClient.post(url, body, options);
  }

  put(url: string, body: any = null, options?: any): Observable<any> {
    url = this.updateUrl(url);
    return this.httpClient.put(url, body, options);
  }

  delete(url: string, options?: any): Observable<any> {
    url = this.updateUrl(url);
    return this.httpClient.delete(url, options);
  }

  private updateUrl(req: string) {
    return this.javEndPoint + req;
  }
}
