import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { EndPointApi } from '../_helpers/endpointapi';
import { Plugin } from '../_models/plugin';

@Injectable({
  providedIn: 'root',
})
export class PluginService {
  @Output() onPluginsChanged: EventEmitter<any> = new EventEmitter();

  private endPointConfig: string = EndPointApi.getURL();
  private javaEndPoint = EndPointApi.getJavaEndpoint();

  constructor(private http: HttpClient) {}

  getPlugins() {
    return this.http.get<Plugin[]>(this.javaEndPoint + 'app/plugins').pipe(
      map((res: any) => {
        if (!res.success) {
          throw new Error(res.message);
        }
        return res.data as any[];
      }),
    );
  }

  installPlugin(plugin: Plugin) {
    return new Observable((observer) => {
      if (environment.serverEnabled) {
        let header = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.http.post<any>(this.endPointConfig + 'api/plugins', { headers: header, params: plugin }).subscribe(
          (result) => {
            observer.next();
            this.onPluginsChanged.emit();
          },
          (err) => {
            console.error(err);
            observer.error(err);
          },
        );
      } else {
        observer.next();
      }
    });
  }

  removePlugin(plugin: Plugin) {
    return new Observable((observer) => {
      if (environment.serverEnabled) {
        let header = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.http
          .delete<any>(this.javaEndPoint + 'app/plugins', {
            headers: header,
            params: { param: plugin.name },
          })
          .subscribe(
            (result) => {
              observer.next();
              this.onPluginsChanged.emit();
            },
            (err) => {
              console.error(err);
              observer.error(err);
            },
          );
      } else {
        observer.next();
      }
    });
  }
}
