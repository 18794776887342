import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn:'root'
})
export class RealtimePricingService {
  constructor(private httpService: HttpService) {
  }

  getAll() {
    return this.httpService.get('realtime-pricing');
  }
}
