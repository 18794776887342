import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../../_models/settings';

@Component({
  selector: 'app-app-setting-realtime-pricing',
  templateUrl: './app-setting-realtime-pricing.component.html',
  styleUrls: ['./app-setting-realtime-pricing.component.scss'],
})
export class AppSettingRealtimePricingComponent implements OnChanges, OnInit {
  @Input() settings: AppSettings;

  pricingList: any[] = [];
  projectList: any[] = [
    { label: 'CDI Turlock', value: 'cdi_turlock' },
    { label: 'CDI Visalia', value: 'cdi_visalia' },
  ];

  locationList: any[] = [{ label: 'WEST', value: 'west' }];
  isoList: any[] = [{ label: 'NY ISO', value: 'ny_iso' }];
  scheduleList: any[] = [
    { label: '5 minutes', value: 5 },
    { label: '15 minutes', value: 15 },
  ];
  constructor(private cdr: ChangeDetectorRef) {

  }
  ngOnInit(): void {
  }

  onChangeProject(value: any) {}

  onAdd() {
    this.settings.pricingList.push({
      project: this.projectList[0].value,
      location: this.locationList[0].value,
      iso: this.isoList[0].value,
      schedule: this.scheduleList[0].value,
    });
  }

  onRemove(idx: number) {
    this.settings.pricingList.splice(idx, 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {settings} = changes;
    if(settings && settings.currentValue) {
      this.pricingList = settings.currentValue.pricingList;
    }
  }
}
