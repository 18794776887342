export const CONTROLS = [
    {
        id: 'html_input',
        class: 'icon-editvalue'
    },
    {
        id: 'value',
        class: 'icon-value'
    },
    {
        id: 'html_button',
        class: 'icon-button'
    },
    {
        id: 'html_select',
        class: 'icon-selectvalue'
    },
    {
        id: 'gauge_progress',
        class: 'icon-progress-v'
    },
    {
        id: 'gauge_semaphore',
        class: 'icon-semaphore'
    },
    {
        id: 'html_chart',
        class: 'icon-chart'
    },
    {
        id: 'html_bag',
        class: 'icon-bag'
    },
    {
        id: 'pipe',
        class: 'icon-pipe'
    },
    {
        id: 'html_slider',
        class: 'icon-slider'
    },
    {
        id: 'html_switch',
        class: 'icon-switch'
    },
    {
        id: 'html_graph-bar',
        class: 'icon-graphbar'
    },
    {
        id: 'own_ctrl-table',
        class: 'icon-table'
    },
    {
        id: 'own_ctrl-iframe',
        class: 'icon-iframe'
    },
];